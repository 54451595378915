import React from "react"

const QuickInfo = () => {
    return (
        <ul>
            <li>The default server is</li>
            <li>The most populated server is</li>
        </ul>
    )
}

export default QuickInfo
